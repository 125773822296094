
  $columns: 12;

  $max-width: 1440px;

  $small-gutters: $spacing-16;
  $medium-gutters: $spacing-16;
  $large-gutters: $spacing-24;

  $small-gutter: $small-gutters / 2;
  $medium-gutter: $medium-gutters / 2;
  $large-gutter: $large-gutters / 2;

  $small-row-margin: $small-gutters * 2;
  $medium-row-margin: $medium-gutters * 2;
  $large-row-margin: $large-gutters * 2;
