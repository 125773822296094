






        $sparta-include-html-global-classes: false;

        @import 'config/_config.scss';
        @import 'utility/grid/_settings.scss';

        @import 'mixins/_mixins.scss';
