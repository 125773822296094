










        @mixin spa-center-absolutely {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        @mixin spa-center-vertically {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        @mixin spa-center-horizontally {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }
