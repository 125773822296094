


  $rem-base: 16px;


  @function strip-unit($num) {
    @return $num / ($num * 0 + 1);
  }

  @function rem-calc($values, $base-value: $rem-base) {
    $max: length($values);

    @if $max == 1 {
      @return strip-unit(nth($values, 1)) * 1px;
    }

    $remValues: ();

    @for $i from 1 through $max {
      $remValues: append($remValues, strip-unit(nth($values, $i)) * 1px);
    }

    @return $remValues;
  }
