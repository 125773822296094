@import '/apps/wt/webtools/sparta_build_server/tmp/9C7439D6/artifacts/components/utilities/global/sparta-style-utility/4.9.5/scss/_sparta-project.scss';
$moduleNameSpace: dynamic-name-space;
[data-sparta-container] {
  .spa-footnotes-section {
    .spa-footnote-back {
      display: none;
      white-space: nowrap;

      &.spa-footnote-back--show {
        display: inline;
      }
    }
  }
  .spa-footnote-link {
    text-decoration: underline;
    font-family: $roboto-medium-family;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  .footnote {
    font-family: $roboto-family;

    &-title1 {
      @include headline-large();
      .spa-footnote-link {
        font-size: 28px;
      }
    }

    &-title2 {
      @include data-xlarge();
      .spa-footnote-link {
        font-size: 20px;
      }
    }

    &-title3 {
      @include data-large();
      .spa-footnote-link {
        font-size: 14px;
      }
    }

    &-title4 {
      @include data();
      .spa-footnote-link {
        font-size: 12px;
      }
    }

    &-title5 {
      @include data-small();
      .spa-footnote-link {
        font-size: 12px;
      }
    }

    &-title6 {
      @include data-xsmall();
    }

  }

  .footnote-darkbg {
    background: $color-regal-one-dark;
    color: $color-brand-tertiary;
    padding: $spacing-24;
    border-radius: $spacing-12 $spacing-12 0 0;
    .spa-footnote-link {
      color: $color-brand-tertiary;
    }
  }
}
