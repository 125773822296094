



$layer-top: 8000;
$nav-layer: 4000;
$layer-modal-dialogs: 2000;
$layer-nonmodal-dialogs: 1000;
$layer-sticky: 600;
$layer-level-3: 400;
$layer-level-2: 200;
$layer-level-1: 100;
$layer-page: 0;
$layer-auto: auto;
$layer-below-page: -100;
