






        @import '_foundation-hacks.scss';

        @import '_centering.scss';

        @import '_bottom-arrow.scss';

        @import '_screen-overlay.scss';

        @import '_card.scss';

        @import '_shadow.scss';

        @import '../utility/icons/icons.scss';

        @import "../utility/typography/typography-helix.scss";

        @function spa-strip-unit($value) {
            @return $value / ($value * 0 + 1);
        }

        @mixin spa-bounce($range) {
            0% { margin-top: 0; }
            50% { margin-top: $range; }
            100% { margin-top: 0; }
        }







        @mixin spa-fontsize($fontsize, $designsize) {
          $rems: spa-strip-unit($fontsize / $rem-base);
          $vm: spa-strip-unit(100 * $fontsize / $designsize);

          font-size: #{$rems}rem;
          font-size: #{$vm}vm; 
          font-size: #{$vm}vmin;
        }
















        @mixin spa-aspect-ratio($width, $height) {
          position: relative;

          &:before {
            display: block;
            content: "";
            width: 100%;
            padding-top: ($height / $width) * 100%;
          }

          > .content {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
          }
        }

        @function str-replace($string, $search, $replace: '') {
          $index: str-index($string, $search);          
          @if $index {
            @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
          }          
          @return $string;
        }



        @function _buildIcon($icon, $parameters) {
          $icon: '%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20xmlns:xlink=%22http://www.w3.org/1999/xlink%22%20x=%22#{map-get($parameters, x)}%22%20y=%22#{map-get($parameters, y)}%22%20width=%22#{map-get($parameters, width)}%22%20height=%22#{map-get($parameters, height)}%22%20viewBox=%22#{map-get($parameters, x)},#{map-get($parameters, y)},#{map-get($parameters, width)},#{map-get($parameters, height)}%22%3E#{$icon}%3C/svg%3E';
          @return $icon;
        }

                @function _buildPath($path, $parameters) {

          $concatPath: '';
          @each $i in $path {
            $concatPath: $concatPath + $i;
          }


          $icon: '%3Cpath%20fill=%22#{map-get($parameters, color)}%22%20stroke=%22#{map-get($parameters, stroke-color)}%22%20stroke-width=%22#{map-get($parameters, stroke-width)}%22%20style=%22#{map-get($parameters, css)}%22%20d=%22#{$concatPath}%22%20/%3E';
          @return str-replace( $icon, '#', '%23' );
        }

        @function buildPaths($parameters) {
          $icons: ();
          @each $name, $path in $iconPaths {            
            $icons: map-merge($icons, ($name: _buildPath($path, $parameters)));
          }

                               @return $icons;
        };

                @function icon($name: 'search', $conf: ()) {        
          $parameters: map-merge((
            color: black, 
            'stroke-color': '',
            'stroke-width': '',
            width: 24,
            height: 24,
            x: 0,
            y: 0,
            css: ''
          ), $conf);
          $icons: buildPaths($parameters);
          $icon: _buildIcon(map-get($icons, $name), $parameters);
          @return url("data:image/svg+xml;charset=utf8,#{$icon}");
        }

        @function icon_by_path($path, $conf: ()) {        
          $parameters: map-merge((
            color: black, 
            'stroke-color': '',
            'stroke-width': '',
            width: 24,
            height: 24,
            x: 0,
            y: 0,
            css: ''
          ), $conf);
          $createPath: _buildPath($path, $parameters);
          $icon: _buildIcon($createPath, $parameters);
          @return url("data:image/svg+xml;charset=utf8,#{$icon}");
        }
