






@import '_foundation-vars.scss';
@import '_breakpoints.scss';
@import '_spacing.scss';
@import '_z-index.scss';


$sparta-include-html-global-classes: true;


$sparta-base-font-size: 16px;
$sparta-base-line-height: 20px;


$body-text-color: $bank-dark-gray;
$body-bg-color: $bank-white;

$body-link-color: $bank-medium-blue;

$input-text-color: $bank-black;

$input-hint-text-color: $mid-gray;
$input-hint-text-color-2: $brown-quaternary;

$input-focus-text-color: $bank-dark-green;
$input-focus-border-color: $bank-light-green;

$input-error-background-color: $bank-error-pink;

$help-layer-background-color: $bank-lightest-blue;
$help-layer-border-color: $bank-light-blue;

$alert-backgrond-color: $bank-alert-yellow;
$alert-border-color: $bank-alert-orange;



$spa-font-family-sans: sans-serif;

$spa-font-size: 16px;
$spa-line-height: 24px;

$roboto-font-family: Arial, "Helvetica Neue", Helvetica, Roboto, $spa-font-family-sans;

$default-font-family: roboto-regular, $roboto-font-family;

$spa-font-family: $default-font-family;



$spa-font-weight-normal: normal !default;
$spa-font-weight-bold  : bold !default;

$spa-font: normal normal $spa-font-weight-normal #{$spa-font-size}/#{$spa-line-height} $spa-font-family;

$spa-standard-transition-property: all;
$spa-standard-transition-time: 0.3s;
$spa-standard-transition-easing: ease;
$spa-standard-transition-delay: 0.0s;

$spa-standard-transition: $spa-standard-transition-property $spa-standard-transition-time $spa-standard-transition-easing $spa-standard-transition-delay;

$link-color: $bank-medium-blue;

$spa-link-color: $link-color;
$spa-link-color-visited: $link-color;
$spa-link-color-hover: $link-color;
$spa-link-color-focus: $link-color;


$cnx-light-family: 'cnx-light';
$cnx-light-italic-family: 'cnx-light-italic';
$cnx-family: 'cnx-regular';
$cnx-italic-family: 'cnx-italic';
$cnx-medium-family: 'cnx-medium';
$cnx-medium-italic-family: 'cnx-medium-italic';
$cnx-bold-family: 'cnx-bold';
$cnx-bold-italic-family: 'cnx-bold-italic';
$cnx-cond-family: 'cnx-regular-cond';
$cnx-italic-cond-family: 'cnx-italic-cond';
$cnx-font-fallbacks: Arial, Helvetica, sans-serif;


$roboto-family: 'roboto-regular';
$roboto-italic-family: 'roboto-italic';
$roboto-medium-family: 'roboto-medium';
$roboto-medium-italic-family: 'roboto-medium-italic';
$roboto-bold-family: 'roboto-bold';
$roboto-bold-italic-family: 'roboto-bold-italic';
$roboto-font-fallbacks: Arial, Helvetica, sans-serif;


$roboto-mono-family: $roboto-family;
$roboto-mono-medium-family: $roboto-medium-family;
$roboto-mono-bold-family: $roboto-bold-family;
$roboto-mono-font-fallbacks: Arial, Helvetica, sans-serif;

$regular-weight: 300;
$light-weight: 200;
$bold-weight: 700;


$type-weight-regular: 400;
$type-weight-light: 300;
$type-weight-bold: 700;


$moduleNameSpace : dynamic-name-space;

$type-size-56: 56px;
$type-size-44: 44px;
$type-size-36: 36px;
$type-size-28: 28px;
$type-size-20: 20px;
$type-size-16: 16px;
$type-size-14: 14px;
$type-size-12: 12px;

$line-height-60: 60px;
$line-height-52: 52px;
$line-height-44: 44px;
$line-height-36: 36px;
$line-height-28: 28px;
$line-height-24: 24px;
$line-height-20: 20px;
$line-height-16: 16px;
