





@mixin shadow-top($color: $color-black) {
  box-shadow: 0 -12px 10px -3px rgba($color, 0.12);
}
@mixin shadow-bottom($color: $color-black) {
  box-shadow: 0 12px 10px 0 rgba($color, 0.12);
}
@mixin shadow-dropdown($color: $color-black) {
  box-shadow: 9px 9px 12px 0 rgba($color, 0.12);
}
@mixin shadow-left($color: $color-black) {
  box-shadow: -15px 0 11px -6px rgba($color, 0.12);
}
@mixin shadow-default($color: $color-black) {
  box-shadow: 0 2px 20px 0  rgba($color, 0.12);
}
@mixin shadow-overlay($color: $color-black) {
  box-shadow: 2px 2px 15px 4px rgba($color,0.8);
}