














$color-brand-primary: #e31837;
$color-primary-s80: #E9465F;
$color-primary-s50: #F18B9B;
$color-primary-s20: #F9D1D7;
$color-primary-s10: #FCE7EB;
$color-primary-s05: #FDF3F5; 
$color-brand-secondary: #012169;
$color-brand-secondary-s80: #344e87;
$color-brand-secondary-s50: #8090b4;
$color-brand-secondary-s20: #ccd3e1;
$color-brand-secondary-s10: #E5E8F0;
$color-brand-secondary-s05: #F2F4F7;
$color-brand-tertiary: #ffffff;








$color-black: #000000;
$color-black-alt: #333333;
$color-gray: #646464;
$color-gray-s70: #919191;
$color-gray-s40: #bababa;
$color-gray-s20: #d5d5d5;
$color-gray-s10: #ededed;
$color-gray-s05: #f5f5f5;








$color-royal-one: #0053c2;
$color-royal-one-dark: #0049AC;
$color-royal-one-s80: #3375ce;
$color-royal-one-s50: #80a8e0;
$color-royal-one-s20: #ccdcf3;
$color-royal-one-s10: #E5EDF8;
$color-royal-one-s05: #F2F6FC;
$color-royal-two: #0073cf;
$color-royal-two-dark: #0064B4;
$color-royal-two-dark-2: #005395;
$color-royal-two-s80: #338fd9;
$color-royal-two-s50: #80b9e7;
$color-royal-two-s20: #cce3f5;
$color-royal-two-s10: #E5F1FA;
$color-royal-two-s05: #F2F8FC;
$color-royal-three-dark: #0395D3;
$color-royal-three-dark-2: #0074A5;
$color-royal-three: #009cde;
$color-royal-three-s80: #33b0e5;
$color-royal-three-s50: #80cdee;
$color-royal-three-s20: #ccebf8;
$color-royal-three-s10: #e0f0f6;
$color-royal-three-s05: #f2fafd;
$color-regal-one: #c41230;
$color-regal-one-dark: #A50E28;
$color-regal-one-s80: #d14159;
$color-regal-one-s50: #e28897;
$color-regal-one-s20: #F3D0D6;
$color-regal-one-s10: #F9E7EA;
$color-regal-one-s05: #FCF3F4;
$color-regal-two: #780032;
$color-regal-two-s80: #93335b;
$color-regal-two-s50: #bb8099;
$color-regal-two-s20: #e4ccd6;
$color-regal-two-s10: #F1E5EA;
$color-regal-two-s05: #F8F2F5;










$color-secondary-one-dark-3: #7A3C02;
$color-secondary-one-dark-2: #B05705;
$color-secondary-one-dark: #ea7000;
$color-secondary-one: #ea7600;
$color-secondary-one-s80: #ee9133;
$color-secondary-one-s50: #f4ba80;
$color-secondary-one-s20: #fbe4cc;

$color-secondary-two-dark-3: #6F4C01;
$color-secondary-two-dark-2: #956702;
$color-secondary-two-dark: #c78800;
$color-secondary-two: #f2a900;
$color-secondary-two-s80: #f5ba33;
$color-secondary-two-s50: #f8d480;
$color-secondary-two-s20: #fceecc;

$color-secondary-three-dark-3: #195F02;
$color-secondary-three-dark-2: #1F7603;
$color-secondary-three-dark: #207804;
$color-secondary-three: #279f00;
$color-secondary-three-s80: #52b233;
$color-secondary-three-s50: #93cf80;
$color-secondary-three-s20: #d4eccc;

$color-secondary-four-dark-3: #1B594F;
$color-secondary-four-dark-2: #26786B;
$color-secondary-four-dark: #34a290;
$color-secondary-four: #40c1ac;
$color-secondary-four-s80: #66cdbd;
$color-secondary-four-s50: #9fe0d5;
$color-secondary-four-s20: #d9f3ee;

$color-secondary-five-dark: #015B6F;
$color-secondary-five: #006980;
$color-secondary-five-s80: #338799;
$color-secondary-five-s50: #80b4bf;
$color-secondary-five-s20: #cce1e6;
$color-secondary-five-s10: #E2F0F2;

$color-secondary-six-dark-2: #8A3447;
$color-secondary-six-dark: #AC435B;
$color-secondary-six: #c74e69;
$color-secondary-six-s80: #d27187;
$color-secondary-six-s50: #e3a6b4;
$color-secondary-six-s20: #f4dce1;
$color-secondary-six-s10: #FCECF0;

$color-secondary-seven-dark: #48194E;
$color-secondary-seven: #5f2167;
$color-secondary-seven-s80: #784a77;
$color-secondary-seven-s50: #aa8eaa;
$color-secondary-seven-s30: #CFBCD1;
$color-secondary-seven-s20: #dacedc;
$color-secondary-seven-s10: #F1E8F0;

$color-secondary-eight-dark: #5D4709;
$color-secondary-eight: #9a7611;
$color-secondary-eight-s80: #ae9141;
$color-secondary-eight-s50: #ccba88;
$color-secondary-eight-s30: #E0D5B7;
$color-secondary-eight-s20: #ebe4cf;
$color-secondary-eight-s10: #F4F1E7;











$color-positive-dark: #005A00;
$color-positive: #007700;
$color-positive-s80: #339233;
$color-positive-s50: #7FBB7F;
$color-positive-s20: #CCE4CC;

$color-negative-dark: #9C0000;
$color-negative: #B41818;
$color-negative-s80: #C34646;
$color-negative-s50: #D98B8B;
$color-negative-s20: #F0D1D1;

$color-neutral-dark: #3E454C;
$color-neutral: #50585F;
$color-neutral-s80: #73797F;
$color-neutral-s50: #A7ABAF;
$color-neutral-s20: #DCDEDF;








$color-apple-dark: #4C5701;
$color-apple: #616F03;
$color-apple-s80: #616F03;
$color-apple-s50: #B0B781;
$color-apple-s20: #DFE2CD;

$color-cabernet-dark: #5A1531;
$color-cabernet: #90204E;
$color-cabernet-s80: #A64D71;
$color-cabernet-s50: #C78FA6;
$color-cabernet-s20: #E9D2DC;

$color-cabernet-dark: #5A1531;
$color-cabernet: #90204E;
$color-cabernet-s80: #A64D71;
$color-cabernet-s50: #C78FA6;
$color-cabernet-s20: #E9D2DC;

$color-midnight-dark: #2741A3;
$color-midnight: #3053D4;
$color-midnight-s80: #5975DD;
$color-midnight-s50: #97A8E9;
$color-midnight-s20: #D6DDF6;

$color-oceanic-dark: #2D4E6F;
$color-oceanic: #365D84;
$color-oceanic-s80: #5E7D9D;
$color-oceanic-s50: #9AAEC1;
$color-oceanic-s20: #D7DFE6;

$color-gold-dark: #554C28;
$color-gold: #7C7043;
$color-gold-s80: #968D69;
$color-gold-s50: #BDB7A0;
$color-gold-s20: #E5E2D9;

$color-platinum-dark: #56524B;
$color-platinum: #706A5F;
$color-platinum-s80: #8C877E;
$color-platinum-s50: #B4B1AC;
$color-platinum-s20: #DDDCDA;








$color-priority-1: #0053c2;
$color-priority-2: #ffcc00;
$color-priority-3: #5c068c;
$color-priority-4: #c41230;
$color-priority-5: #ea7600;
$color-priority-6: #00ad50;
$color-priority-7: #00a9e0;








$color-zelle-1: #6d1ed4;
$color-screen-overlay-hex: #000000;
$color-screen-overlay: rgba($color-screen-overlay-hex, .32);
