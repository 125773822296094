







        @mixin spa-bottom-arrow($bg-color, $arrow-height: 10px) {
            &:after {
                content: '';

                width: 0;
                height: #{$arrow-height};
                position: absolute;
                bottom: -#{$arrow-height};

                border-top: #{$arrow-height} solid #{$bg-color};
                border-left: #{$arrow-height} solid transparent;
                border-right: #{$arrow-height} solid transparent;
                border-bottom: none;

                z-index: 2;

                @include spa-center-horizontally();
            }
        }
