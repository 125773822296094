







@mixin screen-overlay($position: absolute, $color: $color-screen-overlay) {
    position: $position;
    background-color: $color;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}