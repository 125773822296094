@mixin headline-xxlarge {
  font-size: rem-calc(44px) !important;
  line-height: rem-calc(52px) !important;
  @media #{$large-up} {
    font-size: rem-calc(56px) !important;
    line-height: rem-calc(60px) !important;
  }
}

@mixin headline-xlarge {
  font-size: rem-calc(36px) !important;
  line-height: rem-calc(44px) !important;
  @media #{$large-up} {
    font-size: rem-calc(44px) !important;
    line-height: rem-calc(52px) !important;
  }
}

@mixin headline-large {
  font-size: rem-calc(28px) !important;
  line-height: rem-calc(36px) !important;
  @media #{$large-up} {
    font-size: rem-calc(36px) !important;
    line-height: rem-calc(44px) !important;
  }
}

@mixin headline-med {
  font-size: rem-calc(28px) !important;
  line-height: rem-calc(36px) !important;
}

@mixin headline-small {
  font-size: rem-calc(20px) !important;
  line-height: rem-calc(28px) !important;
}

@mixin headline-xsmall {
  font-size: rem-calc(16px) !important;
  line-height: rem-calc(24px) !important;
}

@mixin body-medium {
  font-size: rem-calc(16px) !important;
  line-height: rem-calc(24px) !important;
}

@mixin body-small {
  font-size: rem-calc(14px) !important;
  line-height: rem-calc(20px) !important;
}

@mixin body-xsmall {
  font-size: rem-calc(12px) !important;
  line-height: rem-calc(16px) !important;
}

@mixin label-medium {
  font-size: rem-calc(16px) !important;
  line-height: rem-calc(24px) !important;
}

@mixin label-small {
  font-size: rem-calc(14px) !important;
  line-height: rem-calc(20px) !important;
}

@mixin label-xsmall {
  font-size: rem-calc(12px) !important;
  line-height: rem-calc(16px) !important;
}

@mixin data {
  font-size: rem-calc(16px) !important;
  line-height: rem-calc(24px) !important;
}

@mixin data-small {
  font-size: rem-calc(14px) !important;
  line-height: rem-calc(20px) !important;
}

@mixin data-xsmall {
  font-size: rem-calc(12px) !important;
  line-height: rem-calc(16px) !important;
}

@mixin data-large {
  font-size: rem-calc(20px) !important;
  line-height: rem-calc(32px) !important;
}

@mixin data-xlarge {
  font-size: rem-calc(28px) !important;
  line-height: rem-calc(44px) !important;
}

@mixin data-xlarge-bold {
  font: rem-calc(28px) $roboto-bold-family !important;
  line-height: rem-calc(44px) !important;
}

@mixin data-xlarge-medium {
  font: rem-calc(28px) $roboto-medium-family !important;
  line-height: rem-calc(44px) !important;
}

@mixin data-large-bold {
  font: rem-calc(20px) $roboto-bold-family !important;
  line-height: rem-calc(32px) !important;
}

@mixin data-large-medium {
  font: rem-calc(20px) $roboto-medium-family !important;
  line-height: rem-calc(32px) !important;
}

@mixin data-small-bold {
  font: rem-calc(14px) $roboto-bold-family !important;
  line-height: rem-calc(20px) !important;
}

@mixin data-small-medium {
  font: rem-calc(14px) $roboto-medium-family !important;
  line-height: rem-calc(20px) !important;
}

@mixin data-xsmall-bold {
  font: rem-calc(12px) $roboto-bold-family !important;
  line-height: rem-calc(16px) !important;
}

@mixin data-xsmall-medium {
  font: rem-calc(12px) $roboto-medium-family !important;
  line-height: rem-calc(16px) !important;
}

@mixin data-bold {
  font: rem-calc(16px) $roboto-bold-family !important;
  line-height: rem-calc(24px) !important;
}

@mixin data-medium {
  font: rem-calc(16px) $roboto-medium-family !important;
  line-height: rem-calc(24px) !important;
}
