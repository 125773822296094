





$black: $color-black; 
$white: $color-brand-tertiary; 
$mid-gray: $color-gray; 
$dark-gray: $color-black-alt; 
$light-gray: $color-gray-s20; 








$brand-dawn-red: $color-regal-two-s20; 
$brand-bright-red: $color-brand-primary; 
$brand-regal-red: $color-regal-one; 
$brand-crimson-red:$color-regal-two-s80; 
$brand-dark-red: $color-regal-two; 


$brand-day-blue: $color-secondary-four-s20; 
$brand-lightest-blue: $color-priority-7; 
$brand-light-blue: $color-royal-three; 
$brand-bright-blue: $color-royal-two; 
$brand-royal-blue: $color-royal-one; 
$brand-dark-blue: $color-brand-secondary; 
$brand-night-blue: #000A23;


$brand-pale-gray-light: $color-gray-s05; 
$brand-pale-gray: $color-gray-s10; 
$brand-gray-light: $color-gray-s20; 
$brand-gray: $color-gray-s40; 
$brand-gray-dark: $color-gray-s70; 
$brand-copy-gray-1: $color-gray; 
$brand-copy-gray-2: $color-black-alt; 


$sb-pale-gray: $color-gray-s10; 
$sb-pale-gray-light: $color-gray-s05; 
$sb-dark-gray: $color-gray-s70; 
$sb-warm-gray-light: $color-gray-s20; 


$prt-gold: $color-secondary-two; 
$prt-platinum: $color-gray-s70; 
$prt-platinum-honors: $color-black; 








$red-primary: $brand-bright-red;
$red-secondary: $brand-regal-red;
$red-tertiary: $brand-dark-red;
$red-quarternary: $brand-crimson-red;


$red-violet-primary: $color-regal-two-s80; 
$red-violet-secondary: $color-regal-two; 
$red-violet-tertiary: $color-secondary-seven; 


$blue-primary: $brand-bright-blue;
$blue-secondary: $brand-royal-blue;
$blue-tertiary: $brand-dark-blue;
$blue-quarternary: $brand-light-blue;
$blue-quinary: $color-royal-three-s05; 
$blue-senary: $color-royal-three-s20; 
$blue-septenary: $brand-night-blue;


$light-gray-primary: $color-gray-s05; 
$light-gray-secondary: $color-gray-s05; 
$light-gray-tertiary: $color-gray-s10; 
$light-gray-quarternary: $color-gray-s20; 
$light-gray-quinary: $color-gray-s05; 
$light-gray-senary: $color-gray-s10; 
$light-border-gray: $color-gray-s20; 
$footer-gray: $color-black-alt; 


$cool-gray-primary: $brand-pale-gray-light;
$cool-gray-secondary: $brand-gray-light;
$cool-gray-tertiary: $brand-copy-gray-1;
$cool-gray-quarternary: $brand-copy-gray-2;
$cool-gray-quinary: $color-gray-s70; 


$brown-primary: #857363;
$brown-secondary: #6b5e51;
$brown-tertiary: #524940;
$brown-quaternary: #777766;
$brown-quinary: #A39382;
$brown-senary: #9d8e80;


$orange-primary: #ea7600;
$orange-secondary: #b94700;
$orange-tertiary: #73381d;


$purple-primary: #673bb8;
$purple-secondary: $color-priority-3; 
$purple-tertiary: #4b116f;


$green-primary: #19ac5c;
$green-secondary: #13884a;
$green-tertiary: $color-priority-6; 
$green-quaternary: #007749;
$green-quinary: #154734;


$yellow-primary: $color-secondary-two; 
$yellow-secondary: $color-secondary-two-dark; 
$yellow-tertiary: $color-secondary-eight; 
$yellow-quaternary: $color-secondary-two-s80; 


$light-yellow: $color-secondary-two-s20; 
$orange:$color-secondary-one-s20; 
$light-pink: $color-secondary-six-s20; 









$bank-primary-red: $red-primary;
$bank-regal-red: $red-secondary;
$bank-dark-red: $red-tertiary;

$bright-red: $red-primary;
$regal-red: $red-secondary;
$dark-red: $red-tertiary;
$crimson-red: $red-quarternary;


$bank-primary-blue: $blue-primary;
$bank-medium-blue: $blue-secondary;
$bank-dark-blue: $blue-tertiary;
$bank-edge-blue: $blue-quarternary;
$bank-lightest-blue: $blue-quinary;
$bank-light-blue: $blue-senary;

$lightest-blue: $blue-quinary; 
$light-blue: $blue-senary; 
$bright-blue: $blue-primary;
$royal-blue: $blue-secondary;
$dark-blue: $blue-tertiary;
$night-blue: $blue-septenary;


$bank-dark-green: $green-primary;
$bank-light-green: $green-secondary;

$bright-green: $green-tertiary;
$green: $green-quaternary;
$dark-green: $green-quinary;


$bright-orange: $orange-primary;
$orange: $orange-secondary; 
$dark-orange: $orange-tertiary;


$bright-purple: $purple-primary;
$purple: $purple-secondary;
$dark-purple: $purple-tertiary;


$bright-crimson: $red-violet-primary;
$crimson: $red-violet-secondary;
$dark-crimson: $red-violet-tertiary;


$bank-alert-yellow: $light-yellow;
$bank-alert-orange: $orange;
$bank-error-pink: $light-pink;


$bank-black: $black;
$bank-white: $white;
$bank-light-gray: $light-gray;
$bank-mid-gray: $mid-gray;
$bank-dark-gray: $dark-gray;
$bank-border-gray: $light-border-gray;
$bank-footer-gray: $footer-gray;


$bank-brown-primary: $brown-primary;
$bank-brown-secondary: $brown-secondary;
$bank-brown-tertiary: $brown-tertiary;
$bank-brown-quaternary: $brown-quaternary;
$bank-brown-quinary: $brown-quinary;


$bright-yellow: $yellow-primary;
$yellow: $yellow-secondary;
$dark-yellow: $yellow-tertiary;


$pale-gray-light: $light-gray-primary;
$warm-gray-15-tint: $light-gray-quinary; 
$warm-gray-40-tint: $light-gray-senary; 
$pale-gray: $light-gray-tertiary;
$warm-gray-light: $light-gray-quarternary;
$warm-gray: $brown-senary;
$body-copy-gray: $dark-gray;
$dark-gray: $brown-primary;
$copy-gray-1: $brown-secondary;
$copy-gray-2: $brown-tertiary;


$cool-gray-light: $cool-gray-primary;
$cool-gray: $cool-gray-secondary;
$cool-gray-medium: $cool-gray-tertiary;
$cool-gray-dark: $cool-gray-quarternary;


$gold: $yellow-quaternary;
$platinum: $cool-gray-quinary;
$platinum-honors: $black;


$bank-light-gray-primary: $light-gray-primary;
$bank-light-gray-secondary: $light-gray-secondary;
$bank-light-gray-tertiary: $light-gray-tertiary;
$bank-light-gray-quarternary: $light-gray-quarternary;


$color-secondary-positive-dark: $color-positive-dark;
$color-secondary-positive: $color-positive;
$color-secondary-positive-s80: $color-positive-s80;
$color-secondary-positive-s50: $color-positive-s50;
$color-secondary-positive-s20: $color-positive-s20;

 $color-secondary-negative-dark: $color-negative-dark;
$color-secondary-negative: $color-negative;
$color-secondary-negative-s80: $color-negative-s80;
$color-secondary-negative-s50: $color-negative-s50;
$color-secondary-negative-s20: $color-negative-s20;

 $color-secondary-neutral-dark: $color-neutral-dark;
$color-secondary-neutral: $color-neutral;
$color-secondary-neutral-s80: $color-neutral-s80;
$color-secondary-neutral-s50: $color-neutral-s50;
$color-secondary-neutral-s20: $color-neutral-s20;