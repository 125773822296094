
$spacing-1: 1px;
$spacing-2: 2px;
$spacing-4: 4px;
$spacing-8: 8px;
$spacing-12: 12px;
$spacing-16: 16px;
$spacing-20: 20px;
$spacing-24: 24px;
$spacing-32: 32px;
$spacing-40: 40px;
$spacing-44: 44px;
$spacing-48: 48px;
$spacing-56: 56px;
$spacing-64: 64px;
$spacing-72: 72px;
$spacing-80: 80px;


$spacing-5: 5px;
$spacing-10: 10px;
$spacing-15: 15px;
$spacing-20: 20px;
$spacing-42: 42px;